<template>
  <div class="">
    <div class="my-5">
      <vue-good-table
        :columns="columns"
        :rows="rows"
        @on-cell-click="onCellClick"
        :search-options="{
          enabled: true,
          placeholder: '搜尋',
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'userName', type: 'asc'}
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 10,
          perPageDropdownEnabled: true,
          perPageDropdown: [10, 20],
          nextLabel: '下一頁',
          prevLabel: '上一頁',
          rowsPerPageLabel: 'Rows per page',
          allLabel: '全部',
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'btn'">
            <button type="button" class="btn btn-main btn-sm me-1 mb-1 mb-md-0" style="white-space:nowrap;">查看狀態</button>

            <!-- <p>{{ props.row.id }}</p> -->
          </span>
          <span v-else-if="props.column.field == 'ena'">
            <input type="checkbox" name="ena" id="ena" v-model="props.row.ena">
          </span>
          <span v-else>
            {{props.formattedRow[props.column.field]}}
          </span>
        </template>
      </vue-good-table>
    </div>
    <Meta :title ="metaTitle" />
  </div>
</template>

<script>
/* global $ */
import { mapActions, mapGetters } from 'vuex';
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

const Swal = require('sweetalert2');

export default {
  name: 'Admin__Students',
  components: {
    VueGoodTable,
  },
  data() {
    return {
      page: 1,
      data: [],
      noData: false,
      tempCourse: {},
      is_enabled: true,
      createModalRole: '系統管理員',
      fileUploading: '',
      cid: 0,
      columns: [
        {
          label: '啟用',
          field: 'ena',
        },
        {
          label: '學生名稱',
          field: 'userName',
        },
        {
          label: '學生ID',
          field: 'aspNetUserId',
        },
        {
          label: '加入時間',
          field: 'createTime',
        },
        {
          label: '動作',
          field: 'btn',
        },
      ],
      rows: [

      ],
      metaTitle: '學生管理 / 課程 / 管理 - 元學堂',
    };
  },
  metaInfo: {
    title: "學生管理 / 課程 / 管理 - 元學堂",
    meta: [
      { name: 'description', content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。' },
      {
        property: 'og:title',
        content: '學生管理 / 課程 / 管理 - 元學堂',
      },
      {
        property: 'og:description',
        content: '博碩文化數位影音教學平台，以專業的師資教材，提供您全方位的影音學習環境，突破傳統的學習模式。',
      },
    ]
  },
  methods: {
    getData() {
      const vm = this;
      this.updateLoading(true);
      this.$http.get(`${this.$API_PATH}/Admin/Course/StudentManagement/${this.cid}`, {
        headers: {
          Accept: '*/*',
          Authorization: `bearer ${this.userAuth}`,
        },
      }).then((res) => {
        this.$log.debug(res.data.data.studentList);
        this.updateLoading(false);

        this.data = res.data.data.studentList.map((item) => {
          const time = item.createTime.split('T').join(' ');
          return {
            ...item,
            createTime: time,
            ena: true,
          };
        });
        this.rows = [...this.data];
      }).catch((err) => {
        this.updateLoading(false);
        if (err.response) {
          if (err.response.status == 401) {
            Swal.fire(
              '',
              '無權限取得列表',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          } else if (err.response.status == 404) {
            Swal.fire(
              '',
              '找不到該課程',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        } else {
          Swal.fire(
            '',
            '錯誤，請稍候再試',
            'info',
          ).then((result) => {
            if (result.isConfirmed) {
              vm.$router.push('/');
            }
          });
        }
      });
    },
    onCellClick(param) {
      if (param.column.field == 'ena') {
        const query = {
          enable: !param.row.ena,
        };
        this.$http.put(`${this.$API_PATH}/Admin/Course/Management/Enable/${param.row.id}`, query, {
          headers: {
            Accept: '*/*',
            Authorization: `bearer ${this.userAuth}`,
            'Content-Type': 'application/json',
          },
        }).then((res) => {
          // console.log(res.data);
          this.getData();
        }).catch((err) => {
          this.updateLoading(false);
          if (err.response) {
            if (err.response.status == 401) {
              Swal.fire(
                '',
                '無權限取得列表',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  vm.$router.push('/');
                }
              });
            } else if (err.response.status == 404) {
              Swal.fire(
                '',
                '找不到該課程',
                'info',
              ).then((result) => {
                if (result.isConfirmed) {
                  vm.$router.push('/');
                }
              });
            }
          } else {
            Swal.fire(
              '',
              '錯誤，請稍候再試',
              'info',
            ).then((result) => {
              if (result.isConfirmed) {
                vm.$router.push('/');
              }
            });
          }
        });
      } else if (param.column.field == 'btn') {
        if (param.event.target.innerText == '查看狀態') {
          this.$router.push(`/admin/t/student/${this.cid}/${param.row.aspNetUserId}`);
        }
      }
    },
    save() {
      // connect to api
      this.newUser = {
        userName: '',
        avatar: '',
        email: '',
        account: '',
        role: '',
      };
      this.$refs.img.src = '';
      this.newUser.avatar = '';
      this.$refs.files.value = null;
      this.$refs.img.src = '';
    },
    goToCreate(role) {
      this.$router.push(`/admin/r/user/${role}`);
    },
    ...mapActions(['getUserAuth', 'getUserAuthFromLocal', 'checkExpAuth', 'updateLoading']),
  },
  computed: {
    ...mapGetters(['userName', 'Avatar', 'userId', 'role', 'exp', 'userAuth', 'LoginProvider']),
  },
  created() {
    this.cid = this.$route.params.id;
    this.getUserAuthFromLocal();
    this.checkExpAuth();
    // console.log(this.userAuth);
    if (this.userAuth.length == 0) {
      this.$router.push('/login');
    }
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
  .pageNum {
    cursor: pointer;
    text-decoration: none;
    &.disabled {
      pointer-events: none;
      color: gray;
      cursor: default;
    }
  }
  ::v-deep #vgt-table tbody tr td:nth-of-type(1),
  ::v-deep #vgt-table tbody tr td:nth-of-type(4)
  {
    text-align: center;
  }
</style>
